<script>
let ssoUrl = ''
let apiUrl = ''
let appId = ''
let siteUrl = ''
let mainSiteUrl

switch (process.env.NODE_ENV) {
  case 'development':
    // ssoUrl = 'https://sso.yinbaobiao.com' // sso登录授权
    //  apiUrl = 'http://dev.api.yinbaobiao.com:8083' // api接口地址
    // siteUrl = 'http://dev.adm.yinbaobiao.com:8082' // 本系统地址
    // mainSiteUrl = 'http://dev.www.yinbaobiao.com:8082' // 主站地址
    // appId = 'b24a1abf465545398a3f5d116b3ebd2d' // 本系统站点ID
    // ssoUrl = 'http://dev.sso.yinbaobiao.com:8084' // sso登录授权
    siteUrl = 'http://dev.adm.yinbaobiao.com:8082' // 本系统地址
    mainSiteUrl = 'http://dev.www.yinbaobiao.com:8082' // 主站地址
    appId = 'adm' // 本系统站点ID
    ssoUrl = 'https://sit-sso.yinbaobiao.com'
   apiUrl = 'https://sit-api.yinbaobiao.com'
    break
  case 'sit':
    ssoUrl = 'https://sit-sso.yinbaobiao.com'
    apiUrl = 'https://sit-api.yinbaobiao.com'
    siteUrl = 'https://sit-adm.yinbaobiao.com'
    mainSiteUrl = 'https://sit.yinbaobiao.com' // 主站地址
    appId = 'adm'
    break
  case 'production':
    ssoUrl = 'https://sso.yinbaobiao.com' // sso登录授权
    apiUrl = 'https://api.yinbaobiao.com' // api接口地址
    siteUrl = 'https://adm.yinbaobiao.com' // 本系统地址
    mainSiteUrl = 'https://www.yinbaobiao.com' // 主站地址
    appId = 'adm' // 本系统站点ID
    break
}
export default {
  ssoUrl,
  apiUrl,
  appId,
  siteUrl,
  mainSiteUrl
}
</script>
